<template>
  <div>
    <transition name="fade">
      <choose-login-modal v-show="modal" @closeModal="modal = false"/>
    </transition>
    <transition name="fade">
      <terms-politics-modal v-show="modalTermsPolitics" @closeModal="modalTermsPolitics = false"/>
    </transition>
    <transition name="fade">
      <terms-business-investor-modal v-show="modalTermsBusinessInvestor" @closeModal="modalTermsBusinessInvestor = false"/>
    </transition>
    <transition name="fade">
      <contact-authorizations-modal v-show="modalContact" :contact="autorizacoesContato" @closeModal="modalContact = false"></contact-authorizations-modal>
    </transition>
    <div class="main" :class="mainClass">
      <nav-bar class="container" @login="modal = true"/>
      <div class="content container">
        <div class="title">
          Seja Investidor
        </div>
        <div class="subtitle">
          Preencha os campos abaixo ou entre com sua rede social para conhecer as oportunidades e iniciar seus investimentos
        </div>
        <div v-if="alertSociaLogin" class="mt-4 text-base text-yellow-600">
          Para finalizar seu cadastro preencha os campos abaixo!
        </div>
        <div class="articles">
          <div class="article mr-6">
            <v-input v-capitalizeName name="nomeCompleto" v-model="form.nomeCompleto" :error="errors.first('nomeCompleto')" label="Nome Completo*" v-validate="'required|min:2'"></v-input>
            <v-input v-lowercase v-trim name="email" v-model="form.email" :error="errors.first('email')" label="E-mail*" v-validate="'required|email'"></v-input>
            <v-input name="telefone" v-model="form.telefone" :error="errors.first('telefone')" label="Telefone*" :mask="['(##) ####-####', '(##) #####-####']" :masked="true" v-validate="'required|min:14|max:15'"></v-input>
            <v-input name="codigoConviteCampanha"  type="noautocomplete" v-model="form.codigoConviteCampanha" :error="errors.first('codigoConviteCampanha')" label="Código de indicação (se houver)" v-validate="'min:4|max:15'"></v-input>
            <v-input name="senha" type="password" v-model="password.senha" :error="errors.first('senha')" label="Senha" v-validate="'required|min:6'"></v-input>
            <v-input name="confirmacaoSenha" type="password" v-model="password.confirmacaoSenha" :error="errors.first('confirmacaoSenha')" label="Repetir senha" v-validate="{required: true, min: 6, is: password.senha}"></v-input>
          </div>
          <div class="article">
            <div class="social" >
              <p class="social_title" v-show="!$route.query.i">Cadastrar com as redes sociais</p>
              <div class="social_icons" v-show="!$route.query.i">
                <div @click="facebookAuthenticate" class="social_facebook"></div>
                <!-- <div class="social_linkedin"></div> -->
                <div @click="googleAuthenticate" class="social_google_plus"></div>
                <!-- <div class="social_microsoft">
                  <div class="social_microsoft_icon"></div>
                </div> -->
              </div>
            </div>
            <div class="content_checkboxes">
              <v-checkbox @click="!termos ? modalTermsBusinessInvestor = true : modalTermsBusinessInvestor = false" type="checkbox" class="text-sm" name="termos" :error="errors.first('termos')" v-model="termos" v-validate="'required:true'">
                <span> Declaro que li e estou de acordo com os
                <span class="text-secondary">Termos e Condições para Tomadores e Investidores</span></span>
              </v-checkbox>
              <v-checkbox @click="!termosPrivacidade ? modalTermsPolitics = true : modalTermsPolitics = false" class="text-sm my-2" name="termosPrivacidade" :error="errors.first('termosPrivacidade')" v-model="termosPrivacidade" v-validate="'required:true'">
                <span> Declaro que li e estou de acordo com os
                <span class="text-secondary">Termos e Condições de Uso e Política de Privacidade</span></span>
              </v-checkbox>
              <v-checkbox style="display: none" class="text-sm" name="contact" :modelValue="contactValue" @click.native.prevent="modalContact = true" :label="`Aceito receber informações e contatos por ${contactText}.`"/>
            </div>
          </div>
        </div>
        <div class="buttons">
          <v-button class="items-center" :loading="loading" @click="submit">Avançar</v-button>
        </div>
      </div>
      <div class="footer" :class="modal ? 'blur' : ''">
        <p class="footer_text container">
          Copyright © TuTu Digital. Todos os Direitos Reservados. Tutu Digital Tecnologia Ltda – CNPJ 28.304.222/0001-17, Avenida Paulista, 1374 - Andar 11, São Paulo, SP. CEP 01310-100.
          Esse site está em fase pré-operacional e sendo aprimorado pela equipe da TuTu Digital. Até que esse aprimoramento seja concluído, todas as operações serão realizadas
          a partir do contato individualizada da Tutu Digital com cada cliente.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar/NavBar.vue'
import ChooseLoginModal from '@/components/Modal/ChooseLoginModal'
import ContactAuthorizationsModal from '@/components/Modal/ContactAuthorizationsModal'
import TermsPoliticsModal from '@/components/Modal/TermsPoliticsModal'
import TermsBusinessInvestorModal from '@/components/Modal/TermsBusinessInvestorModal'
import VInput from '@/components/Inputs/Input'
import VCheckbox from '@/components/Inputs/Checkbox'
import VButton from '@/components/Buttons/Button'

import BeInvestor from '@/services/Landing/BeInvestor'
import ConfirmAccount from '@/services/Commons/ConfirmAccount'
import Login from '@/services/Landing/Login'

export default {
  name: 'be-investor',
    /* metaInfo() {
        return {
            title: `Tutu Digital | Teste`,
            meta: [
                { name: 'description', content: 'Teste de descricaodd asdsajd asd'},
                { property: 'og:title', content: 'dasd adad asd'},
                { property: 'og:site_name', content: 'Tutu Digital'},
                { property: 'og:description', content: 'dasd asdasd '},
            ]
        }
     },*/
  props: ['req'],
  components: {
    NavBar,
    ChooseLoginModal,
    ContactAuthorizationsModal,
    TermsPoliticsModal,
    TermsBusinessInvestorModal,
    VInput,
    VButton,
    VCheckbox
  },
  data () {
    return {
      alertSociaLogin: false,
      modalContact: false,
      modalTermsPolitics: false,
      modalTermsBusinessInvestor: false,
      modal: false,
      loading: false,
      error: null,

      form: {
        nomeCompleto: '',
        email: '',
        telefone: '',
        codigoConviteCampanha: ''
      },

      password: {
        userId: null,
        senha: '',
        confirmacaoSenha: ''
      },

      autorizacoesContato: {
        email: true,
        sms: true,
        telefone: true,
        whatsApp: true
      },

      termos: false,
      termosPrivacidade: false
    }
  },
  computed: {
    contactValue () {
      return Object.values(this.autorizacoesContato).some(v => v)
    },
    contactText () {
      const text = {
        sms: 'SMS',
        telefone: 'telefone',
        email: 'e-mail',
        whatsApp: 'WhatsApp'
      }
      if (Object.values(this.autorizacoesContato).every(v => !v)) {
        return Object.values(text).join(', ')
      }
      return Object.entries(this.autorizacoesContato).map(([key, value]) => value ? text[key] : '').filter(v => v).join(', ')
    },

    mainClass(){
      let ret = this.modal ? 'blur' : '';
     // ret += this.$route.query.i === "BLACKFRIDAY2021" ? " main-black" : ""

      return ret;
    }
  },
  methods: {
    async submit () {
      if (!this.loading) {
        if (await this.$validator.validateAll()) {
          this.loading = true
          try {
            const grecaptcha = await this.$recaptcha('login')
            const data = {
              ...this.form,
              autorizacoesContato: this.autorizacoesContato,
              grecaptcha
            }
            if (data.codigoConviteCampanha.trim() === '') delete data.codigoConviteCampanha
            if (!this.password.userId) this.password.userId = (await BeInvestor.signup(data)).data.data.userId
            const checkCreatedPassword = (await ConfirmAccount.checkCreatedPassword(this.password.userId, this.req)).data.data
            if (checkCreatedPassword) {
              this.$store.dispatch('notification', { type: 'success', message: 'Cadastrado com sucesso! Redirecionando...' })

               history.pushState({}, null, '/cadastro-investidor-realizado');
  setTimeout(() => {
      this.$router.push('/investidor/painel')
  }, 1000);

            } else {
              const response = (await BeInvestor.createPassword(this.password)).data.data
              this.$store.dispatch('setLogout')
              this.$store.dispatch('setLogin', response)
              this.$store.dispatch('notification', { type: 'success', message: 'Cadastrado com sucesso! Redirecionando...' })

              if (history.pushState) {
                  history.pushState({}, null, '/cadastro-realizado-investidor');
              }

      ga('send', 'event', 'cadastro-realizado-investidor', 'cadastro-realizado-investidor', 'cadastro-realizado-investidor');
      dataLayer.push({'event': 'cadastro-realizado-investidor'});
      ga('set', 'page', 'cadastro-realizado-investidor');

                setTimeout(() => {
                this.$router.push('/investidor/painel')
           }, 1000);
            }
          } catch (error) {
            this.$store.dispatch('notification', { type: 'error', message: 'Houve um erro. Revise o formulário.' })
            if (error.response.data.erros) {
              Object.entries(error.response.data.erros).forEach(([campo, erro]) => this.errors.add({ field: campo === 'password' ? 'senha' : campo, msg: erro }))
            }
          } finally {
            this.loading = false
          }
        } else {
          this.$store.dispatch('notification', { type: 'error', message: 'Há algum campo inválido. Revise o formulário.' })
        }
      }
    },
    async facebookAuthenticate () {
      try {
        await window.FB.login(async resp => {
          if (resp.status === 'connected') {
            this.loginFacebookAuth(resp.authResponse.accessToken)
          } else {
            this.$store.dispatch('notification', { type: 'error', message: 'Falha ao fazer login com o Facebook.' })
          }
        }, { scope: 'email' })
      } catch (error) {
        console.log(error)
      }
    },
    async loginFacebookAuth (accessToken) {
      const response = (await Login.loginFacebook(accessToken)).data.data
      if (response.autenticacao === null && response.model) {
        const model = response.model
        this.form.nomeCompleto = model.nomeCompleto ? model.nomeCompleto : ''
        this.form.email = model.email ? model.email : ''
        this.form.telefone = model.telefone ? model.telefone : ''
        this.form.codigoConviteCampanha = model.codigoConviteCampanha ? model.codigoConviteCampanha : ''
        this.alertSociaLogin = true
      } else if (response.autenticacao) {
        await this.$store.dispatch('setLogout')
        await this.$store.dispatch('setLogin', response.autenticacao)
        this.$router.push('/investidor/painel')
      } else {
        this.$store.dispatch('notification', { type: 'error', message: 'Falha ao fazer login com o Facebook.' })
      }
    },
    async googleAuthenticate () {
      try {
        const google = await this.$gAuth.signIn()
        const accessToken = google.getAuthResponse().access_token
        if (accessToken) {
          const response = (await Login.loginGoogle(accessToken)).data.data
          if (response.autenticacao === null && response.model) {
            const model = response.model
            this.form.nomeCompleto = model.nomeCompleto ? model.nomeCompleto : ''
            this.form.email = model.email ? model.email : ''
            this.form.telefone = model.telefone ? model.telefone : ''
            this.form.codigoConviteCampanha = model.codigoConviteCampanha ? model.codigoConviteCampanha : ''
            this.alertSociaLogin = true
          } else if (response.autenticacao) {
            await this.$store.dispatch('setLogout')
            await this.$store.dispatch('setLogin', response.autenticacao)
            this.$router.push('/investidor/painel')
          } else {
            this.$store.dispatch('notification', { type: 'error', message: 'Falha ao fazer login com o Google.' })
          }
        } else {
          this.$store.dispatch('notification', { type: 'error', message: 'Falha ao fazer login com o Google.' })
        }
      } catch (error) {
        if (error.response.data.data) {
          const response = error.response.data.data
          const model = response.model
          this.form.nomeCompleto = model.nomeCompleto ? model.nomeCompleto : ''
          this.form.email = model.email ? model.email : ''
          this.form.telefone = model.telefone ? model.telefone : ''
          this.form.codigoConviteCampanha = model.codigoConviteCampanha ? model.codigoConviteCampanha : ''
          this.alertSociaLogin = true
        } else {
          console.log(error)
        }
      }
    }
  },
  mounted () {


    if (this.$route.query.model) {
      const model = this.$route.query.model
      this.form.nomeCompleto = model.nomeCompleto ? model.nomeCompleto : ''
      this.form.email = model.email ? model.email : ''
      this.form.telefone = model.telefone ? model.telefone : ''
      this.form.codigoConviteCampanha = model.codigoConviteCampanha ? model.codigoConviteCampanha : ''
      this.alertSociaLogin = true
    }
    if (this.$route.query.i) {
      this.form.codigoConviteCampanha = this.$route.query.i
      localStorage.setItem('codigoConviteCampanha', this.$route.query.i)
    } else if (localStorage.getItem('codigoConviteCampanha')) {
      this.form.codigoConviteCampanha = localStorage.getItem('codigoConviteCampanha')
    }
  }
}
</script>

<style lang="sass" scoped>

.main
  @apply flex flex-col h-full min-h-screen

.main-black
  background-image: url("http://www.maisaprendizagem.com.br/wp-content/uploads/2018/06/background-black.png")
  background-repeat: no-repeat
  background-attachment: fixed
  background-size: cover
  color: #e0fff3

  ::placeholder
    color: #e0fff3
    opacity: 1



.content
  @apply mt-8 flex flex-col w-full mb-auto

.title
  @apply text-secondary text-2xl font-bold
  @screen lg
    @apply text-4xl

.subtitle
  @apply text-sm text-gray-700 mb-4
  @screen lg
    @apply text-base mb-6

.articles
  @apply flex flex-col
  @screen lg
    @apply flex-row

.article
  @apply w-full flex flex-col
  @screen lg
    @apply w-1/2 justify-between

.social_title
  @apply text-gray-700 mb-3 text-center
  @screen lg
    @apply text-lg text-left

.social
  @apply flex flex-col mb-6
  @screen lg
    @apply mb-0

.social_icons
  @apply flex justify-center
  @screen lg
    @apply justify-start

.social_facebook
  @apply bg-no-repeat w-8 h-8 mr-4 bg-contain  cursor-pointer
  background-image: url("../../../assets/images/login/facebook.svg")
  @screen lg
    @apply w-12 h-12

.social_google_plus
  @apply bg-no-repeat w-8 h-8 mr-4 bg-contain cursor-pointer
  background-image: url("../../../assets/images/login/google.svg")
  @screen lg
    @apply w-12 h-12

.content_checkboxes
  @apply text-gray-700 text-xs text-justify w-full
  @screen lg
    @apply w-11/12 text-sm

.checkbox
  @apply my-2

.buttons
  @apply flex w-full justify-center
  @screen lg
    @apply justify-start

.btn
  @apply w-40 h-10 rounded-full bg-primary text-white self-end my-4
  @screen lg
    @apply w-48
  &:hover:not([disabled]), &:focus:not([disabled])
    @apply outline-none bg-white text-primary border-primary border-2
  &[disabled]
    @apply bg-gray-600

.footer
  @apply w-full bg-gray-200 flex justify-center py-2

.footer_text
  @apply text-center text-sm text-gray-700 leading-relaxed

.blur
  transition-duration: .25s
  filter: blur(8px)
  -webkit-filter: blur(8px)

</style>
