import api from '@/plugins/axios'

const base = 'v1/site/investidor/'

export default {
  signup (data) {
    return api.post(base + 'cadastro', data)
  },
  createPassword (data) {
    return api.post(base + 'criar-senha', data)
  }
}
